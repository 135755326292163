import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { NEW_FRONTEND_URL } from '../main'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter(to, from, next) {
            if(NEW_FRONTEND_URL) {    
                window.location.href = NEW_FRONTEND_URL
                return
            }
            next();
        },
    },
    {
        path: '/monitoring',
        name: 'Monitoring',
        component: () => import('../views/Monitoring.vue'),
        children: [
            {
                path: '/monitoring/info/:configId?',
                component: () => import('../components/MonitoringInfo.vue'),
            },
            {
                path: '/monitoring/edit/:directionId/:configId?/:departmentId',
                component: () => import('../components/MonitoringEdit.vue'),
            }
        ]
    },
    {
        path: '/standard',
        name: 'Standard',
        component: () => import('../views/Standard.vue'),
        children: [
            {
                path: '/standard',
                component: () => import('../components/standard_new/Standard.vue')
            },
            {
                path: '/standard/request/:id',
                component: () => import('../components/standard_new/StandardRequest.vue')
            },
            {
                path: '/standard/requests',
                component: () => import('../components/standard_new/StandardRequests.vue')
            },
            {
                path: '/standard/report/:id?',
                component: () => import('../components/standard_new/StandardReport.vue')
            },
            {
                path: '/standard/vnii/:configId?',
                component: () => import('../components/standard_new/StandardVnii.vue')
            },
        ]
    },
    {
        path: '/analytics',
        name: 'Analytics',
        component: () => import('../views/Analytics.vue'),
        children: [
            {
                path: '/',
                component: () => import('../components/AnalyticsComponent.vue')
            },
        ]
    },

    {
        path: '/passportold',
        name: 'PassportOld',
        component: () => import('../views/Passport.vue'),
        children: [
            {
                path: '/passportold/info/:configId?/:region?/:sectionIndex?',
                component: () => import('../components/PassportInfo.vue')
            },
            {
                path: '/passportold/archive',
                component: () => import('../components/PassportArchive.vue')
            },
        ]
    },
    {
        path: '/passport',
        name: 'Passport',
        component: () => import('../views/Passport.vue'),
        children: [
            {
                path: '/passport/info/:configId?/:region?/:sectionIndex?',
                component: () => import('../components/passport_new/PassportInfo.vue'),
                /*beforeEnter(to, from, next) {
                    if(NEW_FRONTEND_URL) {    
                        window.location.href = `${NEW_FRONTEND_URL}/reg_passport`
                        return
                    }
                    next();
                },*/
            },
            {
                path: '/passport/archive',
                component: () => import('../components/passport_new/PassportArchive.vue'),
            },
        ]
    },
    {
        path: '/passportcznold',
        name: 'PassportCZN',
        component: () => import('../views/PassportCZN.vue'),
        children: [
            {
                path: '/passportcznold/info/:configId?/:departmentId?',
                component: () => import('../components/PassportCZNInfo.vue')
            },
            {
                path: '/passportcznold/massEdit',
                component: () => import('../components/PassportCZNMassEdit.vue')
            }
        ]
    },
    {
        path: '/passportczn/info/:configId?/:departmentId?',
        name: 'PassportCZNNew',
        component: () => import('../components/passportczn_new/PassportCznInfo.vue'),
        beforeEnter(to, from, next) {
            if(NEW_FRONTEND_URL) {    
                window.location.href = `${NEW_FRONTEND_URL}/czn_passport`
                return
            }
            next();
        },
    },
    {
        path: '/event/:configId?',
        name: 'Event',
        component: () => import('../views/Event.vue'),
    },
    {
        path: '/eventRegions/:configId?',
        name: 'EventRegions',
        component: () => import('../views/EventRegions.vue'),
    },
    {
        path: '/infevents',
        name: 'Infevents',
        component: () => import('../views/Infevents.vue'),
        children: [
            {
                path: '/infevents/info/:configId?',
                component: () => import('../components/InfeventsInfo.vue')
            },
            {
                path: '/infevents/edit/:directionId/:configId?',
                component: () => import('../components/InfeventsEdit.vue')
            }
        ]
    },
    {
        path: '/situations',
        name: 'Situations',
        component: () => import('../views/Situations.vue'),
        children: [
            {
                path: '/',
                component: () => import('../components/SituationsRegister.vue')
            },
            {
                path: 'situationInfo/:operation?/:id?',
                component: () => import('../components/SituationInfo.vue')
            },
            {
                path: 'situationChoose',
                component: () => import('../components/SituationChoose.vue')
            },
        ]
    },
    {
        path: '/ankets',
        name: 'Ankets',
        component: () => import('../views/Ankets.vue'),
        
        children: [
            {
                path: '/',
                component: () => import('../components/ankets/AnketsConfigsList.vue'),
                // beforeEnter(to, from, next) {
                //     if(NEW_FRONTEND_URL) {    
                //         window.location.href = `${NEW_FRONTEND_URL}/ankets`
                //         return
                //     }
                //     next();
                // },
            },
            {
                path: 'register/:query?',
                component: () => import('../components/ankets/AnketsRegister.vue'),
                /*beforeEnter(to, from, next) {
                    if(NEW_FRONTEND_URL) {    
                        window.location.href = `${NEW_FRONTEND_URL}/ankets/register?configId=${to.query.configId}`
                        return
                    }
                    next();
                },*/
            },
            {
                path: 'anketInfo/:id',
                component: () => import('../components/ankets/AnketInfo.vue'),
                /*beforeEnter(to, from, next) {
                    if(NEW_FRONTEND_URL) {    
                        window.location.href = `${NEW_FRONTEND_URL}/ankets/info/${to.id}`
                        return
                    }
                    next();
                },*/
            }
        ]
    },
    {
        path: '/clientcentricity',
        name: 'ClientCentricity',
        component: () => import('../views/ClientCentricity.vue'),
        children: [
            {
                path: '/clientcentricity/checklist',
                component: () => import('../components/icc_new/ClientCentricityCheckList')
            },
            {
                path: '/clientcentricity/anket',
                component: () => import('../components/icc_new/ClientCentricityAnket')
            }
        ]
    },
    {
        path: '/unsubscribe',
        component: () => import('../components/config/Unsubscribe.vue')
    },
    {
        path: '/clientindex',
        name: 'ClientIndex',
        component: () => import('../views/ClientIndex.vue'),
        children: [
            {
                path: '/',
                component: () => import('../components/icc_new/ClientIndex.vue'),
                /*beforeEnter(to, from, next) {
                    if(NEW_FRONTEND_URL) {    
                        window.location.href = `${NEW_FRONTEND_URL}/clientindex`
                        return
                    }
                    next();
                },*/
            },
        ]
    },
    {
        path: '/orgmonitor',
        name: 'OrgMonitor',
        component: () => import('../views/OrgMonitor.vue'),
        children: [
            {
                path: '/',
                component: () => import('../components/orgmonitor/OrgMonitor.vue')
            },
            {
                path: '/orgmonitor/archive',
                component: () => import('../components/orgmonitor/OrgMonitorArchive.vue')
            },
        ]
    },
    {
        path: '/processmonitor',
        name: 'ProcessMonitor',
        component: () => import('../views/ProcessMonitor.vue'),
        children: [
            {
                path: '/',
                component: () => import('../components/processmonitor/ProcessMonitor.vue')
            },
            {
                path: '/processmonitor/archive',
                component: () => import('../components/orgmonitor/OrgMonitorArchive.vue')
            },
        ]
    },
    {
        path: '/integralmonitor',
        name: 'IntegralMonitor',
        component: () => import('../views/IntegralMonitor.vue'),
        children: [
            {
                path: '/',
                component: () => import('../components/integralmonitor/IntegralMonitor.vue')
            },
            {
                path: '/integralmonitor/archive',
                component: () => import('../components/orgmonitor/OrgMonitorArchive.vue')
            },
        ]
    },
    {
        path: '/resultmonitor',
        name: 'ResultMonitor',
        component: () => import('../views/ResultMonitor.vue'),
        children: [
            {
                path: '/',
                component: () => import('../components/resultmonitor_new/ResultMonitor.vue')
            },
            {
                path: '/resultmonitor/archive',
                component: () => import('../components/orgmonitor/OrgMonitorArchive.vue')
            },
        ]
    },
    {
        path: '/resultmonitor_old',
        name: 'ResultMonitorOld',
        component: () => import('../views/ResultMonitor.vue'),
        children: [
            {
                path: '/',
                component: () => import('../components/resultmonitor/ResultMonitor.vue')
            },
            {
                path: '/resultmonitor_old/archive',
                component: () => import('../components/orgmonitor/OrgMonitorArchive.vue')
            },
        ]
    },
    {
        path: '/config',
        name: 'Config',
        component: () => import('../views/Config.vue'),
        children: [
            {
                path: '/',
                component: () => import('../components/config/ConfigComponent.vue')
            },
            {
                path: 'ratings2023',
                component: () => import('../components/config/Ratings2023Config.vue')
            },
            {
                path: 'ratings2023ConfigEdit/:id?',
                component: () => import('../components/config/Rating2023ConfigEdit.vue')
            },
            {
                path: 'monitoring',
                component: () => import('../components/config/MonitoringConfig.vue')
            },
            {
                path: 'monitoringConfigEdit/:id?',
                component: () => import('../components/config/MonitoringConfigEdit.vue')
            },
            {
                path: 'passport',
                component: () => import('../components/config/new/PassportConfig.vue')
            },
            {
                path: 'passportConfigEdit/:id?',
                component: () => import('../components/config/new/PassportConfigEdit.vue')
            },
            {
                path: 'passportczn',
                component: () => import('../components/config/new/PassportCznConfig.vue')
            },
            {
                path: 'passportCZNConfigEdit/:id?',
                component: () => import('../components/config/new/PassportCznConfigEdit.vue')
            },
            {
                path: 'analyticalReport',
                component: () => import('../components/config/new/AnalyticalReportConfig.vue')
            },
            {
                path: 'analyticalReportConfigEdit/:id?',
                component: () => import('../components/config/new/AnalyticalReportConfigEdit.vue')
            },
            {
                path: 'situations',
                component: () => import('../components/config/SituationsConfig.vue')
            },
            {
                path: 'situationsConfigAdd/:id?',
                component: () => import('../components/config/SituationsConfigAdd.vue')
            },
            {
                path: 'ankets',
                component: () => import('../components/config/AnketsConfig.vue')
            },
            {
                path: 'anketsConfigEdit/:id?',
                component: () => import('../components/config/AnketsConfigEdit.vue')
            },
            {
                path: 'infevents',
                component: () => import('../components/config/InfeventsConfig.vue')
            },
            {
                path: 'infeventsConfigEdit/:id?',
                component: () => import('../components/config/InfeventsConfigEdit.vue')
            },
            {
                path: 'messages',
                component: () => import('../components/config/Messages.vue')
            },
            {
                path: 'notify',
                component: () => import('../components/config/Notify.vue')
            },
            {
                path: 'logs',
                component: () => import('../components/config/Logs.vue')
            },
            {
                path: 'users',
                component: () => import('../components/config/UsersManagement.vue')
            },
            {
                path: 'userEdit/:id?',
                component: () => import('../components/config/UserEdit.vue')
            },
            {
                path: 'departments',
                component: () => import('../components/config/DepartmentsManagement.vue')
            },
            {
                path: 'departmentEdit/:id?',
                component: () => import('../components/config/DepartmentEdit.vue')
            },
            {
                path: 'dataload',
                component: () => import('../components/config/DataLoad.vue')
            },
            {
                path: 'sysinfo',
                component: () => import('../components/config/SysInfo.vue')
            },
            {
                path: 'notifConfig',
                component: () => import('../components/config/NotifConfig.vue')
            },
            {
                path: 'event',
                component: () => import('../components/config/new/EventConfig.vue')
            },
            {
                path: 'eventConfigEdit/:id?',
                component: () => import('../components/config/new/EventConfigEdit.vue')
            },
            {
                path: 'standard',
                component: () => import('../components/config/new/StandardConfig.vue')
            },
            {
                path: 'standardEdit/:id?',
                component: () => import('../components/config/new/StandardConfigEdit.vue')
            },
            {
                path: 'clientCentricity',
                component: () => import('../components/config/ClientCentricityConfig.vue')
            },
            {
                path: 'clientCentricityConfigEdit/:id?',
                component: () => import('../components/config/ClientCentricityConfigEdit.vue')
            },
            {
                path: 'indexClient',
                component: () => import('../components/config/indexClient.vue')
            },
            {
                path: 'indexClientEdit',
                component: () => import('../components/config/indexClientEdit.vue')
            },
        ]
    },
    {
        path: '/serviceczn',
        name: 'ServiceCZN',
        component: () => import('../views/ServiceCZN.vue'),
        children: [
            {
                path: '/serviceczn',
                component: () => import('../components/ServiceCZN.vue')
            },
            {
                path: '/serviceczn/config',
                component: () => import('../components/ServiceCZNConfig.vue')
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('../views/NotFoundPage.vue')
    },

    // --- РЕАЛИЗОВАННЫЕ В НОВОЙ ВЕРСИИ ----

    {
        path: '/storage',
        name: 'storage',
        beforeEnter() {
            if(NEW_FRONTEND_URL) {    
                window.location.href = `${NEW_FRONTEND_URL}/storage`
                return
            }
        },
        component: () => {}
    },
    {
        path: '/indicators',
        name: 'indicators',
        beforeEnter() {
            if(NEW_FRONTEND_URL) {    
                window.location.href = `${NEW_FRONTEND_URL}/indicators`
                return
            }
        },
        component: () => {}
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
